import React from "react";
import "./thanksBlock.scss";
import Image from "../../../UI/Images/Image";
import Button from "../../../UI/Button/Button";

const ThanksBlock = ({ className = "", onClick }) => {
  return (
    <div className={`thanks-block ${className}`}>
      <Image src={"svg/thanks.svg"} className="thanks-block__icon" />
      <span className="thanks-block__text span">Заявка отправлена</span>
      <Button
        className="thanks-block__btn dark js-close"
        onClick={onClick}
        text={"Закрыть"}
      />
    </div>
  );
};
export default ThanksBlock;
