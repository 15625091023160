import React, { useState } from "react";
import { LanguageContext } from "../../contexts/LanguageContext";
import Parser from "html-react-parser";
import examples from "../../content/portfolio.json";
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import "./portfolioSection.scss";
import Image from "../../components/UI/Images/Image";
import Button from "../../components/UI/Button/Button";

const PortfolioSection = ({ callback }) => {
  const [picturesToShow, setPicturesToShow] = useState(examples.slice(0, 4));

  const handleClick = (e) => {
    e.preventDefault();
    if (picturesToShow.length !== 4) {
      const element = document.querySelector("#portfolio");

      element.scrollIntoView();
    }
    setPicturesToShow((prevState) =>
      prevState.length === 4 ? examples : examples.slice(0, 4)
    );
  };

  return (
    <LanguageContext.Consumer>
      {(context) => {
        return (
          <section
            className={
              "pp-section pp-section-portfolio  js-link-desktop js-link"
            }
            data-label={context.getTranslate("portfolio.subtitle")}
            id="portfolio"
          >
            <div className={"container"}>
              <Image
                className={"background"}
                useLazy={false}
                src={"desktop/portfolio/bg.png"}
              />
              <TitleBlock
                title={"portfolio.title"}
                subtitle={"portfolio.subtitle"}
                className={"centered white"}
              />
              <div className={"list"}>
                {picturesToShow.map((example, index) => {
                  return (
                    <div
                      key={index}
                      className={"example"}
                      onClick={() => {
                        if (window.innerWidth > 768) {
                          callback(examples, index);
                        }
                      }}
                    >
                      <Image src={example.image} />
                      <div className={"text"}>
                        <span className={"title"}>
                          {Parser(context.getTranslate(example.title))}
                        </span>
                        <span className={"subtitle"}>
                          {Parser(context.getTranslate(example.subtitle))}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <Button
                text={
                  picturesToShow.length === 4
                    ? "portfolio.button"
                    : "portfolio.buttonHide"
                }
                className={"bright"}
                onClick={handleClick}
              />
            </div>
          </section>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default PortfolioSection;
