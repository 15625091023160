import React from "react";
import formJSON from "../../../content/form2.json";
import Form from "../../../components/Form/Form";
import Button from "../../../components/UI/Button/Button";
import API from "../../../Helpers/API";

class MainForm2 extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      fields: formJSON,
      loading: false,
      data: {},
      busy: false,
    };
  }

  componentDidMount() {
    if (this.props.type) {
      this.setActiveType(this.props.type);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      if (this.props.type) {
        this.setActiveType(this.props.type);
      }
    }
  }

  setActiveType(type) {
    const data = this.state.data;
    data["category"] = type;
    this.setState({ data });
  }

  onInputChange = (input) => {
    let data = this.state.data;
    data[input.name.replace(this.props.idPrefix, "")] = input.value;
    this.setState({ data });
  };

  forceValidate() {
    return this.formRef.current.forceValidate();
  }

  forceClose() {
    this.setState({ fields: formJSON }, () => {
      this.formRef.current.resetForm();
    });
  }

  onSubmit() {
    if (!this.state.busy) {
      if (this.forceValidate()) {
        this.setState({ busy: true, loading: true });
        let data = this.state.data;
        data["type"] = "Заполните форму для заказа";
        data["token"] =
          "CQqUpvwVocwrpUdVrdfODcUYOeqqSztvqKpFfMBtPXLeSPajTfYxiSwWXMmQlFlB";
        API("", data, this.onSuccess.bind(this));
      }
    }
  }

  onSuccess() {
    this.setState({ busy: false, loading: false });
    const fields = formJSON;
    fields.map((field) => {
      field.value = "";
      field.activePlaceholder = "";
    });
    this.setState({ fields: fields, data: {} }, () => {
      this.formRef.current.resetForm();
    });
    this.props.onSubmit();
  }

  render() {
    const { fields } = this.state;

    return (
      <div className={"form form-main"}>
        <Form
          ref={this.formRef}
          embedded={true}
          fields={fields}
          idPrefix={this.props.idPrefix}
          onChangeInput={this.onInputChange}
        />
        <div className={"controls"}>
          <Button
            className={"bright"}
            onClick={this.onSubmit.bind(this)}
            text={"Отправить заявку"}
            loading={this.state.loading}
          />
          <span>
            Нажимая на кнопку «Отправить заявку», вы соглашаетесь с 
            <span className={"link js-open-terms"}>
              правилами обработки персональных данных
            </span>
          </span>
        </div>
      </div>
    );
  }
}

export default MainForm2;
