import React from "react";
import { LanguageContext } from "../../contexts/LanguageContext";
import Parser from "html-react-parser";
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import pricelist from "../../content/prices.json";
import "./pricesSection.scss";
import PriceBlock from "./PriceBlock/PriceBlock";
import Image from "../../components/UI/Images/Image";
import { BrushesBlock } from "../../components/BrushesBlock/BrushesBlock";

const PricesSection = ({ onClick }) => {
  return (
    <LanguageContext.Consumer>
      {(context) => {
        return (
          <section
            className={"pp-section pp-section-prices js-link  js-link-desktop"}
            data-label={"Услуги и цены"}
          >
            <div className={"container"}>
              <TitleBlock title={"prices.title"} subtitle={"prices.subtitle"} />
              <div className={"list"}>
                {pricelist.map((price, index) => {
                  return (
                    <PriceBlock
                      key={index}
                      buttonText={
                        index !== 2
                          ? "prices.button_portrait"
                          : "prices.button_painting"
                      }
                      data={price}
                      callback={onClick}
                    />
                  );
                })}
              </div>
              <div className={"disclaimer"}>
                <Image src={"desktop/icons/warning.svg"} />
                <span>{Parser(context.getTranslate("prices.precaution"))}</span>
              </div>
              <BrushesBlock />
            </div>
          </section>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default PricesSection;
