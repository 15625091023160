import React from "react";
import PropTypes from "prop-types";
import { LanguageContext } from "../../../contexts/LanguageContext";
import "./textInput.scss";

const TextInput = ({
  input,
  changeInput,
  idPrefix = "",
  focusInput,
  blurInput,
}) => {
  if (
    !input.inputId.includes(idPrefix) &&
    !input.name.includes(idPrefix) &&
    idPrefix
  ) {
    input.inputId = idPrefix + input.inputId;
    input.name = idPrefix + input.name;
  }
  const [focused, setFocused] = React.useState("");
  const inputRef = React.createRef();

  const onFocus = (event) => {
    if (!focused) {
      setFocused("focused");
    }
    if (focusInput) focusInput(input.id, input, event);
  };

  const onBlur = (event) => {
    if (focused && !event.currentTarget.value) {
      setFocused("");
    }
    if (blurInput) blurInput(input.id, input, event);
  };

  return (
    <LanguageContext.Consumer>
      {(context) => {
        return (
          <div
            className={`text-input ${
              input.isRequired ? "required" : ""
            } ${focused}`}
          >
            <div className={"block"}>
              <label htmlFor={input.inputId}>
                {context.getTranslate(input.label)}
              </label>
              <input
                ref={inputRef}
                id={input.inputId}
                type={input.type}
                name={input.name}
                value={input.value}
                checked={input.checked}
                placeholder={context.getTranslate(input.placeholder)}
                onFocus={(event) => onFocus(event)}
                onBlur={(event) => onBlur(event)}
                onChange={(event) => changeInput(input.id, input, event)}
              />
            </div>
            <span className={"message"}>
              {context.getTranslate(input.errorMsg)}
            </span>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

TextInput.propTypes = {
  input: PropTypes.object.isRequired,
  changeInput: PropTypes.func.isRequired,
  blurInput: PropTypes.func,
  focusInput: PropTypes.func,
};

export default TextInput;
