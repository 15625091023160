import React from "react";
import Popup from "../Popup";
import Svg from "../../UI/SvgSprite/Svg";
import "../PopupForm/popupForm.scss";
import ThanksBlock from "../PopupForm/ThanksBlock/ThanksBlock";

class PopupThanks extends Popup {
  constructor(props) {
    super(props);
  }

  onSubmit() {}

  beforeClose() {}

  render() {
    return (
      <div className={"pp-popup pp-popup-order pp-popup-form"} ref={this.ref}>
        <div className={"close js-close"}>
          <Svg name={"close"} />
        </div>
        <div className={`box sent`}>
          <div className={"overlay js-close"} />
          <div className={"content"}>
            <ThanksBlock
              onClick={() => {
                this.close();
                this.setState({ sent: false });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PopupThanks;
