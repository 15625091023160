import React from "react";
import { LanguageContext } from "../../contexts/LanguageContext";
import Image from "../../components/UI/Images/Image";
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import Parser from "html-react-parser";
import "./aboutSection.scss";

const AboutSection = () => {
  const [isMobile, setMobile] = React.useState(window.innerWidth < 768);

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setMobile(window.innerWidth < 768);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setMobile(window.innerWidth < 768);
      });
    };
  });
  return (
    <LanguageContext.Consumer>
      {(context) => {
        return (
          <section
            className={
              "pp-section pp-section-about no-bottom  js-link-desktop js-link"
            }
            data-label={"Обо мне"}
          >
            <div className={"container"}>
              <Image
                src={`${isMobile ? "mobile" : "desktop"}/about/marina.png`}
              />
              <div className={"content"}>
                <TitleBlock title={"about.title"} subtitle={"about.subtitle"} />
                <p>{Parser(context.getTranslate("about.text"))}</p>
                <div className={"software"}>
                  <div>
                    <Image src={"desktop/about/procreate.jpg"} />
                    <span>Procreate</span>
                  </div>
                  <div>
                    <Image src={"desktop/about/ai.svg"} />
                    <span>Adobe Illustrator</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default AboutSection;
