/**
 * User: denisverstov
 * Date: 06/01/2020
 * Time: 22:23
 */

import React from "react";
import axios from "axios";
import PropTypes from "prop-types";

import Input from "./Input";

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      inputs: props.fields,
      formHasError: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({ inputs: this.props.fields });
    }
  }

  resetForm() {
    this.state.inputs.map((input) => {
      input.value = "";
      input.hasError = false;
      if (input.type === "select") {
        input.value = input.options[0].value;
        input.options.map((option, index) => {
          if (index === 0) {
            return (option.isActive = true);
          }
          return (option.isActive = false);
        });
      }
      this.removeError(input.id);
      this.removeClass(input.id, "focused success has-error");
    });
  }

  // const {fields, url, token, beforeSend, singleField, formSuccess, formClass, formFail, btnText = "Submit", btnClass = "", btnSvg, useBtnSubmit = false, dataTitle, dataRequest, embedded, onChangeInput, ref} = this.props;

  changeInput(id, input, event) {
    let value = event.target ? event.target.value : event;
    value = this.validate(input, value);
    this.setState({
      inputs: this.state.inputs.map((obj) => {
        if (obj.id === id) obj.value = value;
        if (this.props.onChangeInput) this.props.onChangeInput(obj);
        return obj;
      }),
    });
  }

  forceValidate() {
    let hasError = false;
    this.state.inputs.map((input) => {
      this.validate(input, input.value, true);
      if (input.hasError) {
        hasError = true;
      }
    });
    return !hasError;
  }

  changeSelect(id, input, event, option) {
    // let value = event?.target.value;
    this.setState({
      inputs: this.state.inputs.map((obj) => {
        if (obj.inputId === id) {
          if (obj.type === "select") {
            obj.value = option.value;
            obj.activePlaceholder = option.label;
            obj.options.map((selectOption) => {
              selectOption.isActive = selectOption.value === option.value;
            });
          }
        }

        if (this.props.onChangeInput) this.props.onChangeInput(obj);
        return obj;
      }),
    });
    this.validate(input, input.value);
  }

  validate(input, value, isBlur = false) {
    let id = input.id;
    let name = input.name;
    let isRequired = input.isRequired;
    let hasSuccess = !isBlur ? input.inputClass.includes("success") : true;

    const validator = input["validator"] || name;
    switch (validator) {
      case "name":
        if (value.length >= 2 && isRequired) this.removeError(id);
        if (value.length < 2 && isRequired && hasSuccess) this.addError(id);
        break;
      case "phone":
        // value = value.replace(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g, "");
        let testPhone = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/g.test(value);
        if (testPhone && value.length >= 11 && value.length <= 11 && isRequired)
          this.removeError(id);
        if (
          ((value.length < 11 || value.length > 11) &&
            isRequired &&
            hasSuccess) ||
          !testPhone
        )
          this.addError(id);
        break;
      case "email":
        let test =
          /^[a-zA-Z0-9\.\-_]{1,}\@([a-zA-Z0-9\-_]{1,}\.){1,2}[a-zA-Z]{2,100}$/.test(
            value
          );
        if (test && isRequired) this.removeError(id);
        if (!test && hasSuccess && isRequired) this.addError(id);
        break;
      case "numeric":
        let testNumeric = /^([0-9_ :.'"-]+)$/.test(value);
        if (testNumeric && isRequired) this.removeError(id);
        if (!testNumeric && hasSuccess && isRequired) this.addError(id);
        break;
      case "password":
        let passConfCorrect = false;
        let passConfId = "";
        this.state.inputs.map((input) => {
          if (input.validator === "password_repeat" && input.value === value) {
            passConfCorrect = input.value.length >= 8;
            passConfId = input.id;
          }
        });
        passConfCorrect
          ? this.removeError(passConfId)
          : this.addError(passConfId);
        value.length >= 8 ? this.removeError(id) : this.addError(id);
        break;
      case "password_repeat":
        let passCorrect = false;
        this.state.inputs.map((input) => {
          if (input.validator === "password" && input.value === value) {
            passCorrect = true;
          }
        });
        passCorrect && input.value.length >= 8
          ? this.removeError(id)
          : this.addError(id);
        break;
      case "image":
        value.size <= 10 * 1024 * 1024 && value.size > 10
          ? this.removeError(id)
          : this.addError(id);
        break;
      default: {
        if (value.length >= 2 && isRequired) {
          this.removeError(id);
        }
        if (value.length < 2 && isRequired && hasSuccess) {
          this.addError(id);
        }
        break;
      }
    }

    return value;
  }

  addError(id) {
    this.removeClass(id, "success");
    this.addClass(id, "has-error");
    this.setState({
      inputs: this.state.inputs.map((input) => {
        if (input.id === id) input.hasError = true;
        return input;
      }),
    });
  }

  removeError(id) {
    this.addClass(id, "success");
    this.removeClass(id, "has-error");
    this.setState({
      inputs: this.state.inputs.map((input) => {
        if (input.id === id) input.hasError = false;
        return input;
      }),
    });
  }

  removeClass(id, className) {
    this.setState({
      inputs: this.state.inputs.map((input) => {
        if (input.id === id)
          input.inputClass = input.inputClass.replace(` ${className}`, " ");
        return input;
      }),
    });
  }

  addClass = (id, className) => {
    this.setState({
      inputs: this.state.inputs.map((input) => {
        if (input.id === id && !input.inputClass.includes(className))
          input.inputClass += ` ${className}`;
        return input;
      }),
    });
  };

  blurInput(id, input, event) {
    if (!input.value.length) {
      this.removeClass(id, "focused");
    } else {
      this.validate(input, event.target.value, true);
    }
  }

  focusInput(id) {
    this.addClass(id, "focused");
  }

  formSubmit(event) {
    event.preventDefault();
    const data = {};
    let hasError = false;

    const form = this.formRef.current;

    this.state.inputs.map((input) => {
      this.validate(input, input.value, true);
      if (input.hasError) hasError = true;
      data[input.name] = input.value;
    });

    if (hasError) return false;

    axios
      .post(this.props.formUrl, data)
      .then((response) => this.onSuccess(response, form))
      .catch((error) => this.onFail(error, form));
  }

  onFail(response, form) {
    return this.props.formFail ? this.props.formFail(response, form) : true;
  }

  onSuccess(response, form) {
    this.state.inputs.map((input) => {
      input.value = "";
      input.hasError = false;
      if (input.type === "select") {
        input.label = input.labelDefault;
        input.options.map((option) => {
          return (option.isActive = false);
        });
      }
      this.removeClass(input.id, "focused success");
    });

    return this.props.formSuccess
      ? this.props.formSuccess(response, form)
      : true;
  }

  returnForm() {
    const { formClass, btnSvg, btnText, btnClass, useBtnSubmit } = this.props;
    return (
      <form
        className={`form-feedback ${formClass ? formClass : ""}`}
        onSubmit={(event) => this.formSubmit(event)}
        ref={this.formRef}
      >
        {this.state.inputs.map((input) => {
          return (
            <Input
              input={input}
              idPrefix={this.props.idPrefix}
              changeSelect={this.changeSelect.bind(this)}
              changeInput={this.changeInput.bind(this)}
              blurInput={this.blurInput.bind(this)}
              focusInput={this.focusInput.bind(this)}
              key={input.id}
            />
          );
        })}
      </form>
    );
  }

  render() {
    return this.returnForm();
  }
}

Form.propTypes = {
  fields: PropTypes.array.isRequired,
  beforeSend: PropTypes.func,
  formSuccess: PropTypes.func,
  formFail: PropTypes.func,
  btnText: PropTypes.string,
  btnClass: PropTypes.string,
  formClass: PropTypes.string,
  useBtnSubmit: PropTypes.bool,
};

export default Form;
