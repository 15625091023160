import React from "react";
import PropTypes from "prop-types";
import "./textarea.scss";
import ErrorMessage from "../ErrorMessage";
import { LanguageContext } from "../../../contexts/LanguageContext";

const TextArea = ({ input, changeInput, focusInput, blurInput, idPrefix }) => {
  const [focused, setFocused] = React.useState("");
  const onFocus = (event) => {
    if (!focused) {
      setFocused("focused");
    }
    if (focusInput) focusInput(input.id, input, event);
  };
  const onBlur = (event) => {
    if (focused && !event.currentTarget.value) {
      setFocused("");
    }
    if (blurInput) blurInput(input.id, input, event);
  };
  return (
    <LanguageContext.Consumer>
      {(context) => {
        return (
          <div
            className={`input textarea ${focused} ${
              input.hasError ? "has-error" : ""
            } ${input.inputClass || ""}`}
          >
            {input.label ? (
              <label htmlFor={input.inputId}>
                {context.getTranslate(input.label)}
              </label>
            ) : (
              ""
            )}
            <textarea
              id={idPrefix ? idPrefix + input.inputId : input.inputId}
              name={idPrefix ? idPrefix + input.name : input.name}
              rows={3}
              value={input.value}
              onFocus={(event) => onFocus(event)}
              onBlur={(event) => onBlur(event)}
              onChange={(event) => changeInput(input.id, input, event)}
              placeholder={context.getTranslate(input.placeholder)}
            />
            <ErrorMessage message={context.getTranslate(input.errorMessage)} />
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

TextArea.propTypes = {
  input: PropTypes.object.isRequired,
  changeInput: PropTypes.func.isRequired,
  blurInput: PropTypes.func,
  focusInput: PropTypes.func,
};

export default TextArea;
