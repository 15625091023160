import React from "react";
import Image from "../../components/UI/Images/Image";
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import "./Hero.scss";
import Button from "../../components/UI/Button/Button";
import { BannerBlock } from "../../components/BannerBlock/BannerBlock";
import TGIcon from "./TgIcon";

const Hero = () => {
  return (
    <section className={"pp-section-hero"}>
      <div className={"container"}>
        <div className={"content"}>
          <div className={"text"}>
            <TitleBlock
              isMain={true}
              title={"titles.hero"}
              subtitle={"links.base_text"}
            />
            <p>
              Картины по фото с детальной проработкой и печатью на холсте.
              Натуральные материалы и красители, профессиональное оборудование.
            </p>
            <div className="buttons">
              <Button
                className={"bright js-open-order"}
                text={"Заказать картину"}
              />
              <Button
                className={"telegram"}
                text={"Telegram"}
                link="https://t.me/romanova_arty"
                prefix={
                  <div className="tgIcon">
                    <TGIcon />
                  </div>
                }
              />
            </div>
          </div>
          <BannerBlock />
        </div>
        <div className={"hint"}>
          <Image useLazy={false} src={"desktop/icons/mouse.svg"} />
          <span>Подробности ниже</span>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = {};

export default Hero;
