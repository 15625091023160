import React from "react";
import { LanguageContext } from "../../../contexts/LanguageContext";
import Parser from "html-react-parser";
import Image from "../../../components/UI/Images/Image";
import "./processBlock.scss";

const ProcessBlock = ({ data, index, onClick, extended }) => {
  const {
    image,
    title,
    text,
    popupTitle,
    popupText,
    color,
    fullTitle,
    fullText,
  } = data;

  return (
    <LanguageContext.Consumer>
      {(context) => {
        return (
          <div
            className={`process-block ${extended ? "extended" : ""}`}
            style={{ backgroundColor: color || "" }}
            onClick={onClick}
          >
            <Image src={image} />
            <div className={"content"}>
              <div className={"text"}>
                <span className={"index"}>
                  {index < 10 ? `0${index + 1}` : index + 1}
                </span>
                <span className={"title"}>
                  {Parser(context.getTranslate(extended ? fullTitle : title))}
                </span>
                <p>
                  {Parser(context.getTranslate(extended ? fullText : text))}
                </p>
              </div>
              {!extended ? (
                <div className={"link"}>
                  <span>Подробнее</span>
                  <svg
                    width="7"
                    height="10"
                    viewBox="0 0 7 10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.41431 5.00008L1.7072 9.70718L0.292986 8.29297L3.58588 5.00008L0.292986 1.70718L1.7072 0.292969L6.41431 5.00008Z"
                    />
                  </svg>
                </div>
              ) : null}
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default ProcessBlock;
