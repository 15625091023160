import React from "react";
import Popup from "../Popup";
import Svg from "../../UI/SvgSprite/Svg";
import Image from "../../UI/Images/Image";
import { LanguageContext } from "../../../contexts/LanguageContext";
import Swipe from "react-easy-swipe";

import "./arrayPopup.scss";
import PopupContentBlock from "./PopupContentBlock/PopupContentBlock";

class ArrayPopup extends Popup {
  constructor(props) {
    super(props);
    this.player = {};
    this.state = {
      array: props.array,
      position: props.position,
    };
    this.youtubeConfig = {
      width: "100%",
      height: "100%",
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log(this.props, prevProps);
    if (prevProps !== this.props) {
      this.setState({ array: this.props.array, position: this.props.position });
    }
  }

  // beforeOpen() {
  //     try {
  //         this.player.playVideo();
  //     } catch (e) {}
  // }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  beforeClose() {
    // this.setState({position: 0});
    // try {
    //     // this.player.pauseVideo();
    // } catch (e) {
    // }
  }

  _onReady(event) {
    this.player = event.target;
    // event.target.pauseVideo();
  }

  changePosition(pos) {
    this.setState({ position: pos });
  }

  onSwipeStart(event) {}

  render() {
    const { array, position } = this.state;
    const element = array ? array[position] : {};
    const firstSlide = position === 0;
    const lastSlide = array ? position === array.length - 1 : 0;

    return (
      <LanguageContext.Consumer>
        {(context) => {
          return (
            <div className={"pp-popup pp-popup-array"} ref={this.ref}>
              <div className={"close js-close"}>
                <Svg name={"close"} />
              </div>

              <div
                className={`arrow arrow-prev ${firstSlide ? "disabled" : ""}`}
                onClick={() => {
                  if (!firstSlide) this.changePosition(position - 1);
                }}
              >
                <Image useLazy={false} src={"svg/control-arrow.svg"} />
              </div>

              <Swipe
                onSwipeStart={this.onSwipeStart}
                className={"content-box"}
                onSwipeRight={() => {
                  if (
                    !firstSlide &&
                    !(
                      window.innerWidth <= 768 &&
                      element?.popupType === "process"
                    )
                  )
                    this.changePosition(position - 1);
                }}
                onSwipeLeft={() => {
                  if (
                    !lastSlide &&
                    !(
                      window.innerWidth <= 768 &&
                      element?.popupType === "process"
                    )
                  )
                    this.changePosition(position + 1);
                }}
              >
                <div className={"overlay"} />
                <div
                  className={`box ${element?.popupType || ""} ${
                    element?.popupTitle ? "has-title" : ""
                  }`}
                >
                  <PopupContentBlock
                    element={element}
                    position={position}
                    onReady={this._onReady.bind(this)}
                  />
                </div>
              </Swipe>

              <div
                className={`arrow arrow-next ${lastSlide ? "disabled" : ""}`}
                onClick={() => {
                  if (!lastSlide) this.changePosition(position + 1);
                }}
              >
                <Image useLazy={false} src={"svg/control-arrow.svg"} />
              </div>
            </div>
          );
        }}
      </LanguageContext.Consumer>
    );
  }
}

export default ArrayPopup;
