import axios from "axios";

const API = (url, data, callback, error, method = "POST", headers = {}) => {
  url = "https://" + process.env.REACT_APP_API_URL;

  const formSuccess = (response) => {
    if (response && (response.status === 200 || response.status === 201)) {
      const data = response.data;
      if (data && data.length > 0) {
        return error ? error(data[0]) : [];
      }

      return callback ? callback(data) : data;
    } else {
      return error ? error(data) : [];
    }
  };

  const formError = (err) => (error ? error(err) : []);

  let bodyFormData = new FormData();

  for (let key in data) {
    bodyFormData.append(key, data[key]);
  }

  // console.log(method, headers, JSON.stringify(data), bodyFormData);

  // const requestOptions = {
  //   method: method,
  //   headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify(data)
  // };
  // fetch(url, requestOptions)
  //     .then(response => response.json())
  //     .then(data => this.setState({ postId: data.id }));

  const request =
    method === "GET"
      ? axios.get(url, headers)
      : axios.post(url, bodyFormData, headers);
  request
    .then((response) => formSuccess(response))
    .catch((err) => formError(err));
};

export default API;
