import React from "react";
import { LanguageContext } from "../../../contexts/LanguageContext";
import Parser from "html-react-parser";
import "./priceBlock.scss";
import Button from "../../../components/UI/Button/Button";

const PriceBlock = ({ data, buttonText, callback }) => {
  const { type, title, prices } = data;
  return (
    <LanguageContext.Consumer>
      {(context) => {
        return (
          <div className={"price-block"}>
            <div className={"wrapper"}>
              <span className={"type"}>
                {Parser(context.getTranslate(type))}
              </span>
              <h2 className={"title"}>{Parser(context.getTranslate(title))}</h2>
              <div className={"price-list"}>
                {prices.map((price, index) => {
                  return (
                    <div className={"price"} key={index}>
                      <div className={"price__item"}>
                        <span className={"name"}>
                          {Parser(context.getTranslate(price.title))}
                        </span>
                        <span className={"value"}>
                          {Parser(context.getTranslate(price.price))}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <Button
              text={buttonText}
              className={"bright"}
              onClick={() => {
                callback(context.getTranslate(title));
              }}
            />
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};
export default PriceBlock;
