import React from "react";
import "./Header.scss";
import Image from "../../components/UI/Images/Image";
import Button from "../../components/UI/Button/Button";
import Svg from "../../components/UI/SvgSprite/Svg";

const Header = ({}) => {
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  const isInstagram = ua.indexOf("Instagram") > -1;

  const [isMobile, setMobile] = React.useState(window.innerWidth < 1025);
  const [nodeList, setNodeList] = React.useState({});

  // setNodeList(a)

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setMobile(window.innerWidth < 768);
    });
    if (Object.keys(nodeList).length < 1) {
      let arr = {};
      document.querySelectorAll(".js-link-desktop").forEach((section) => {
        arr[section.dataset.label] = section;
      });
      setNodeList(arr);
    }
    return () => {
      window.removeEventListener("resize", () => {
        setMobile(window.innerWidth < 768);
      });
    };
  });

  return (
    <header>
      <div className={"container"}>
        <Image
          className={"logo"}
          useLazy={false}
          src={`${isMobile ? "mobile" : "desktop"}/logo.svg`}
        />
        <div className={"menu"}>
          {!isMobile && !isInstagram && !ua.match(/instagram/i) ? (
            <>
              <nav>
                {Object.keys(nodeList).map((key, index) => {
                  return (
                    <span
                      onClick={() => {
                        nodeList[key].scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                          inline: "nearest",
                        });
                      }}
                      key={index}
                    >
                      {key}
                    </span>
                  );
                })}
              </nav>
              <Button className={"dark js-open-feedback"} text={"Связаться"} />
            </>
          ) : (
            <div className={"menu-button js-open-menu"}>
              <span>Меню</span>
              <Svg name={"menu"} />
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {};

export default Header;
