import React from "react";
import Popup from "../Popup";
import Svg from "../../UI/SvgSprite/Svg";
import TitleBlock from "../../UI/TitleBlock/TitleBlock";
import Button from "../../UI/Button/Button";
import "./popupPolicy.scss";

class PopupPolicy extends Popup {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={"pp-popup pp-popup-policy pp-popup-modal"} ref={this.ref}>
        <div className={"close js-close"}>
          <Svg name={"close"} />
        </div>
        <div className={"box"}>
          <div className={"overlay"}></div>
          <div className={"content"}>
            <TitleBlock
              title={"Правила обработки персональных данных"}
              subtitle={"Соглашение"}
            />
            <div className={"policy"}>
              <p>
                Предоставляя свои персональные данные Пользователь даёт согласие
                на обработку, хранение и использование своих персональных данных
                на основании ФЗ № 152-ФЗ «О персональных данных» от 27.07.2006
                г. в следующих целях:
              </p>
              <ul>
                <li>Осуществление клиентской поддержки</li>
                <li>
                  Получения Пользователем информации о маркетинговых событиях
                </li>
                <li>
                  Проведения аудита и прочих внутренних исследований с целью
                  повышения качества предоставляемых услуг
                </li>
              </ul>
              <p>
                Под персональными данными подразумевается любая информация
                личного характера, позволяющая установить личность
                Пользователя/Покупателя такая как:
              </p>
              <ul>
                <li>Имя</li>
                <li>Адрес электронной почты</li>
              </ul>
              <p>
                Персональные данные Пользователей хранятся исключительно на
                электронных носителях и обрабатываются с использованием
                автоматизированных систем, за исключением случаев, когда
                неавтоматизированная обработка персональных данных необходима в
                связи с исполнением требований законодательства.
              </p>
              <p>
                Владелец сайта обязуется не передавать полученные персональные
                данные третьим лицам, за исключением случаев получения запроса
                от уполномоченных органов государственной власти РФ только по
                основаниям и в порядке, установленным законодательством РФ.
              </p>
              <p>
                Владелец сайта оставляет за собой право вносить изменения
                в одностороннем порядке в настоящие правила, при условии,
                что изменения не противоречат действующему законодательству РФ.
                Изменения условий настоящих правил вступают в силу после их
                публикации на Сайте.
              </p>
            </div>
            <Button className={"js-close dark"} text={"Понятно"} />
          </div>
        </div>
      </div>
    );
  }
}

export default PopupPolicy;
