import React from "react";
import Image from "../../components/UI/Images/Image";
import { LanguageContext } from "../../contexts/LanguageContext";
import "./formSection.scss";
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import MainForm from "./MainForm/MainForm";

const FormSection = ({ callback }) => {
  return (
    <LanguageContext.Consumer>
      {(context) => {
        return (
          <section
            className={"pp-section pp-section-form js-link js-link-desktop"}
            data-label={"Контакты"}
          >
            <div className={"container small"}>
              <TitleBlock
                title={"Свяжитесь любым удобным способом"}
                subtitle={"Контакты"}
                className={"centered"}
              />
              <div className={"links"}>
                <a
                  href={context.getTranslate("links.telegram")}
                  className={"instagram"}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Image src={"desktop/icons/tg_icon.svg"} />
                  <div className={"text"}>
                    <span className={"title"}>
                      {context.getTranslate("links.instagram_text")}
                    </span>
                    <span className={"subtitle"}>написать в Telegram</span>
                  </div>
                </a>
                <a
                  href={context.getTranslate("links.email")}
                  className={"email"}
                >
                  <Image src={"desktop/icons/email.svg"} />
                  <div className={"text"}>
                    <span className={"title"}>Email</span>
                    <span className={"subtitle"}>написать на почту</span>
                  </div>
                </a>
              </div>
              <div className={"form-title"}>
                <div className={"line"} />
                <span>Или заполните форму</span>
                <div className={"line"} />
              </div>
              <MainForm idPrefix={"footer-"} onSubmit={callback} />
            </div>
          </section>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default FormSection;
