import React from "react";
import { LanguageContext } from "../../../contexts/LanguageContext";
import Image from "../../../components/UI/Images/Image";
import Parser from "html-react-parser";
import "./feature.scss";

const Feature = ({ icon, text, color = "#DCF0E4" }) => {
  return (
    <LanguageContext.Consumer>
      {(context) => {
        return (
          <div className={`feature`} style={{ background: color }}>
            <Image useLazy={false} src={icon} className={"icon"} />
            <p>{Parser(context.getTranslate(text))}</p>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Feature;
