import React from "react";
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import "./processOfWork.scss";
import { content } from "./content";

export const ProcessOfWork = () => {
  return (
    <section
      className="pp-section pp-section-process-of-work js-link"
      data-label="Процесс работы"
    >
      <div className="container">
        <TitleBlock title={content.title} subtitle={content.subtitle} />
        <span className="description">{content.description}</span>
        <div className="card-wrapper">
          {content.cards.map((card) => (
            <div className="card">
              <h2 className="card__step">{card.step}</h2>
              <p className="card__description">{card.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
