import React, { useEffect, useState } from "react";
import { Transition } from "react-transition-group";
import Image from "../UI/Images/Image";
import { imageList } from "./constants";
import "./bannerBlock.scss";

export const BannerBlock = () => {
  const [seconds, setSeconds] = useState(0);
  const [inProp, setInProp] = useState(true);
  const duration = 5000;
  const timeout = 1500;

  useEffect(() => {
    setInProp(true);

    const timeout = setTimeout(() => {
      setInProp(false);
    }, duration);

    const interval = setInterval(() => {
      if (seconds >= imageList.length - 1) {
        setSeconds(0);
      } else {
        setSeconds((seconds) => seconds + 1);
      }
    }, duration);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <Transition in={inProp} timeout={timeout} mountOnEnter>
      {(state) => (
        <div className={`fadeImage ${state}`}>
          <Image useLazy={false} src={imageList[seconds]} />
        </div>
      )}
    </Transition>
  );
};
