import React from "react";
import formJSON from "../../../content/form.json";
import Form from "../../../components/Form/Form";
import Button from "../../../components/UI/Button/Button";
import API from "../../../Helpers/API";

class MainForm extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      fields: formJSON,
      data: {},
      busy: false,
      loading: false,
    };
  }

  componentDidMount() {
    if (this.props.type) {
      this.setActiveType(this.props.type);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      if (this.props.type) {
        this.setActiveType(this.props.type);
      }
    }
  }

  setActiveType(type) {
    const fields = this.state.fields;
    fields.map((field) => {
      if (field.id === "reg-category") {
        field.options.map((option) => {
          option.isActive = type === option.label;
          if (type === option.label) {
            field.activePlaceholder = option.label;
          }
        });
      }
    });

    const data = this.state.data;
    data["category"] = this.props.type;
    this.setState({ data });

    this.setState({ fields });
  }

  onInputChange = (input) => {
    const save = () => {
      let data = this.state.data;
      data[input.name.replace(this.props.idPrefix, "")] = input.value;
      this.setState({ data });
    };
    save();
  };

  forceValidate() {
    return this.formRef.current.forceValidate();
  }

  forceClose() {
    this.setState({ fields: formJSON }, () => {
      this.formRef.current.resetForm();
    });
  }

  onSubmit() {
    if (!this.state.busy) {
      if (this.forceValidate()) {
        this.setState({ busy: true, loading: true });
        let data = this.state.data;
        // console.log(data);
        data["type"] = "Заполните форму для заказа";
        data["token"] =
          "CQqUpvwVocwrpUdVrdfODcUYOeqqSztvqKpFfMBtPXLeSPajTfYxiSwWXMmQlFlB";
        API("", data, this.onSuccess.bind(this));
      }
    }
  }

  onSuccess() {
    this.setState({ busy: false, loading: false });
    const fields = formJSON;
    fields.map((field) => {
      if (field.type === "select") {
        field.value = field.options[0].value;
      } else {
        field.value = "";
      }
      field.activePlaceholder = "";
    });
    this.setState({ fields: fields, data: {} }, () => {
      this.formRef.current.resetForm();
    });
    this.props.onSubmit();
  }

  render() {
    const { fields } = this.state;

    console.log("fields", fields);
    return (
      <div className={"form form-main"}>
        <Form
          ref={this.formRef}
          embedded={true}
          fields={fields}
          idPrefix={this.props.idPrefix}
          onChangeInput={this.onInputChange}
        />
        <div className={"controls"}>
          <Button
            className={"bright"}
            onClick={this.onSubmit.bind(this)}
            text={"Отправить заявку"}
            loading={this.state.loading}
          />
          <span>
            Нажимая на кнопку «Отправить заявку», вы соглашаетесь с 
            <span className={"link js-open-terms"}>
              правилами обработки персональных данных
            </span>
          </span>
        </div>
      </div>
    );
  }
}

export default MainForm;
