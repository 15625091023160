import React from "react";
import Popup from "../Popup";
import Svg from "../../UI/SvgSprite/Svg";
import TitleBlock from "../../UI/TitleBlock/TitleBlock";
import "./popupForm.scss";
import FeedbackForm from "./FeedbackForm";
import ThanksBlock from "../PopupForm/ThanksBlock/ThanksBlock";

class PopupFeedback extends Popup {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
    };
  }

  onSubmit() {
    this.setState({ sent: true });
  }
  beforeClose() {
    this.setState({ sent: false });
  }

  render() {
    const { sent } = this.state;
    return (
      <div
        className={"pp-popup pp-popup-order pp-popup-form pp-popup-modal"}
        ref={this.ref}
      >
        <div className={"close js-close"}>
          <Svg name={"close"} />
        </div>
        <div className={`box ${sent ? "sent" : ""}`}>
          <div className={"overlay"}></div>
          <div className={"content"}>
            {!sent ? (
              <TitleBlock
                title={"Заполните форму для заказа"}
                subtitle={"Форма обратной связи"}
              />
            ) : null}
            {sent ? (
              <ThanksBlock
                onClick={() => {
                  this.close();
                  this.setState({ sent: false });
                }}
              />
            ) : (
              <FeedbackForm onSubmit={this.onSubmit.bind(this)} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PopupFeedback;
