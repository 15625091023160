import React from "react";

const TGIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0039 0C6.99403 0 5.52883 0.00516967 5.24402 0.0288024C4.2159 0.114287 3.57615 0.276208 2.87917 0.623314C2.34204 0.890106 1.91843 1.19936 1.50035 1.63288C0.738959 2.42347 0.277507 3.3961 0.110461 4.55226C0.0292458 5.11354 0.00561946 5.22801 0.000820359 8.09496C-0.00102545 9.05061 0.000820359 10.3083 0.000820359 11.9953C0.000820359 17.0025 0.00635778 18.4666 0.0303533 18.7509C0.113415 19.7516 0.270308 20.3812 0.602553 21.0699C1.23751 22.3882 2.45021 23.3778 3.87886 23.7471C4.37354 23.8745 4.9199 23.9446 5.6213 23.9778C5.91848 23.9908 8.94744 24 11.9783 24C15.0091 24 18.0399 23.9963 18.3297 23.9815C19.1418 23.9433 19.6134 23.88 20.1349 23.7452C21.5728 23.3741 22.7633 22.3993 23.4112 21.0625C23.737 20.3905 23.9022 19.7369 23.9769 18.7884C23.9932 18.5816 24 15.2847 24 11.9922C24 8.69907 23.9926 5.40821 23.9764 5.20142C23.9007 4.23765 23.7355 3.5896 23.3992 2.90461C23.1232 2.34389 22.8168 1.92515 22.372 1.49699C21.5781 0.738524 20.6072 0.276947 19.4503 0.11004C18.8897 0.0289871 18.778 0.00498504 15.9096 0H12.0039Z"
      fill="url(#paint0_radial_1116_81)"
    />
    <path
      d="M12.0039 0C6.99403 0 5.52883 0.00516967 5.24402 0.0288024C4.2159 0.114287 3.57615 0.276208 2.87917 0.623314C2.34204 0.890106 1.91843 1.19936 1.50035 1.63288C0.738959 2.42347 0.277507 3.3961 0.110461 4.55226C0.0292458 5.11354 0.00561946 5.22801 0.000820359 8.09496C-0.00102545 9.05061 0.000820359 10.3083 0.000820359 11.9953C0.000820359 17.0025 0.00635778 18.4666 0.0303533 18.7509C0.113415 19.7516 0.270308 20.3812 0.602553 21.0699C1.23751 22.3882 2.45021 23.3778 3.87886 23.7471C4.37354 23.8745 4.9199 23.9446 5.6213 23.9778C5.91848 23.9908 8.94744 24 11.9783 24C15.0091 24 18.0399 23.9963 18.3297 23.9815C19.1418 23.9433 19.6134 23.88 20.1349 23.7452C21.5728 23.3741 22.7633 22.3993 23.4112 21.0625C23.737 20.3905 23.9022 19.7369 23.9769 18.7884C23.9932 18.5816 24 15.2847 24 11.9922C24 8.69907 23.9926 5.40821 23.9764 5.20142C23.9007 4.23765 23.7355 3.5896 23.3992 2.90461C23.1232 2.34389 22.8168 1.92515 22.372 1.49699C21.5781 0.738524 20.6072 0.276947 19.4503 0.11004C18.8897 0.0289871 18.778 0.00498504 15.9096 0H12.0039Z"
      fill="url(#paint1_linear_1116_81)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.42861 11.875C8.92716 10.3556 11.2562 9.34603 12.4257 8.85624C15.7543 7.46681 16.454 7.22691 16.9038 7.21692C17.0038 7.21692 17.2237 7.23691 17.3736 7.35686C17.4936 7.45682 17.5236 7.58677 17.5436 7.68672C17.5636 7.78668 17.5835 7.99659 17.5636 8.15653C17.3836 10.0557 16.604 14.6638 16.2041 16.7829C16.0342 17.6826 15.7043 17.9824 15.3845 18.0124C14.6848 18.0724 14.155 17.5526 13.4853 17.1128C12.4257 16.4231 11.8359 15.9933 10.8064 15.3135C9.61687 14.5339 10.3865 14.104 11.0663 13.4043C11.2462 13.2244 14.3149 10.4256 14.3749 10.1757C14.3849 10.1457 14.3849 10.0257 14.3149 9.96577C14.2449 9.9058 14.145 9.92579 14.065 9.94578C13.9551 9.96577 12.2758 11.0853 9.00712 13.2944C8.52732 13.6242 8.0975 13.7842 7.70766 13.7742C7.27784 13.7642 6.45818 13.5343 5.83844 13.3344C5.08876 13.0945 4.48901 12.9645 4.53898 12.5447C4.56897 12.3248 4.86885 12.1049 5.42861 11.875Z"
      fill="white"
    />
    <defs>
      <radialGradient
        id="paint0_radial_1116_81"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(6.37503 25.8485) rotate(-90) scale(23.7858 22.1226)"
      >
        <stop stopColor="#FFDD55" />
        <stop offset="0.1" stopColor="#FFDD55" />
        <stop offset="0.5" stopColor="#FF543E" />
        <stop offset="1" stopColor="#C837AB" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_1116_81"
        x1="11.99"
        y1="0"
        x2="11.99"
        y2="23.8102"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2AABEE" />
        <stop offset="1" stopColor="#229ED9" />
      </linearGradient>
    </defs>
  </svg>
);

export default TGIcon;
