import React from "react";
import Popup from "../Popup";
import Svg from "../../UI/SvgSprite/Svg";
import TitleBlock from "../../UI/TitleBlock/TitleBlock";
import "./popupForm.scss";
import NotificationForm from "./NotificationForm";
import ThanksBlock from "../PopupForm/ThanksBlock/ThanksBlock";

class PopupNotification extends Popup {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
    };
  }

  onSubmit() {
    this.setState({ sent: true });
  }
  beforeClose() {
    this.setState({ sent: false });
  }

  render() {
    const { type, apiURL, prefix, apiType } = this.props;
    const { sent } = this.state;
    return (
      <div
        className={"pp-popup pp-popup-notification pp-popup-form "}
        ref={this.ref}
      >
        <div className={"close js-close"}>
          <Svg name={"close"} />
        </div>
        <div className={"box"}>
          <div className={"overlay"}></div>
          <div className={"content"}>
            {!sent ? (
              <TitleBlock
                title={
                  type === "course"
                    ? "Уведомление о старте курса"
                    : "Индивидуальное обучение"
                }
                subtitle={"Заявка"}
              />
            ) : null}
            {sent ? (
              <ThanksBlock
                onClick={() => {
                  this.close();
                  this.setState({ sent: false });
                }}
              />
            ) : (
              <NotificationForm
                apiType={apiType}
                onSubmit={this.onSubmit.bind(this)}
                prefix={prefix}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PopupNotification;
