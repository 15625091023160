import React from "react";
import LanguageProvider from "./contexts/LanguageContext";

import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

import Main from "./pages/Main/Main";

import "./App.scss";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.recaptcha = React.createRef();
    this.state = {};
  }

  render() {
    return (
      <LanguageProvider>
        <Main />
      </LanguageProvider>
    );
  }
}

export default App;
