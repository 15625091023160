import React from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import "./errorMessage.scss";
import { LanguageContext } from "../../contexts/LanguageContext";
import Svg from "../UI/SvgSprite/Svg";

const ErrorMessage = ({ message, className }) => {
  return (
    <LanguageContext.Consumer>
      {(context) => {
        return (
          <div className={`error-message ${className || ""}`}>
            <span>{Parser(context.getTranslate(message || "form.error"))}</span>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
};

export default ErrorMessage;
