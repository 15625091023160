import React from "react";
import PropTypes from "prop-types";
import ScrollArea from "react-scrollbar";

import { LanguageContext } from "../../../contexts/LanguageContext";

import "./select.scss";
import Image from "../../UI/Images/Image";
import Svg from "../../UI/SvgSprite/Svg";

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      options: this.props.input.options,
      label: "",
    };
  }

  onClick(id, input, event, option) {
    this.props.changeInput(id, input, event, option);
    this.toggleSelect();
  }

  toggleSelect() {
    this.setState({ opened: !this.state.opened });
  }

  componentDidMount() {
    this.state.options.forEach((option) => {
      if (option.isActive)
        this.props.changeInput(
          this.props.input.inputId,
          this.props.input,
          "",
          option
        );
    });
    this.setLabel();
    window.addEventListener("click", this.clickOutside.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.clickOutside.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.input.options !== prevState.options) {
      this.setState({ options: this.props.input.options });
      this.setLabel();
    }
  }

  clickOutside(event) {
    const target = event.target;
    if (!target.closest(".select") && this.state.opened) {
      this.toggleSelect();
    }
  }

  setLabel() {
    this.props.input.options.map((option) => {
      if (option.isActive) {
        this.setState({ label: option.label });
      }
    });
  }

  render() {
    const { input, className, useMarkers, idPrefix = "" } = this.props;
    const scrollable = this.state.options.length > 4;
    if (
      !input.inputId.includes(idPrefix) &&
      !input.name.includes(idPrefix) &&
      idPrefix
    ) {
      input.inputId = idPrefix + input.inputId;
      input.name = idPrefix + input.name;
    }
    return (
      <LanguageContext.Consumer>
        {(context) => {
          return (
            <div
              className={`select ${this.state.opened ? "opened" : ""} ${
                className || ""
              } ${this.props.direction || ""} ${useMarkers ? "map" : ""} ${
                this.state.options.length === 0 ? "inactive" : ""
              }`}
            >
              {/*<span className={"message"}>{input.errorMsg}</span>*/}
              <input id={input.inputId} type={"hidden"} name={input.name} />

              {/*<label htmlFor={input.inputId}>*/}
              {/*    {context.getTranslate(input.label)}*/}
              {/*</label>*/}

              <div className={"select-body"}>
                <div
                  className={"select-visible"}
                  onClick={this.toggleSelect.bind(this)}
                >
                  <div className={"label"}>
                    <span>{context.getTranslate(input.placeholder)}</span>
                    <span className={"value"}>
                      {context.getTranslate(
                        input.activePlaceholder || this.state.label
                      )}
                    </span>
                  </div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.7071 3L7 7.70711L8.41421 9.12132L11.7071 5.82843L15 9.12132L16.4142 7.70711L11.7071 3Z" />
                    <path d="M11.7071 21.8284L7 17.1213L8.41421 15.7071L11.7071 19L15 15.7071L16.4142 17.1213L11.7071 21.8284Z" />
                  </svg>
                </div>

                <div className={"select-hidden"}>
                  <div>
                    <ScrollArea
                      className={`list ${scrollable ? "scrollable" : ""}`}
                      minScrollSize={50}
                      stopScrollPropagation={scrollable}
                    >
                      {this.state.options
                        ? this.state.options.map((option, index) => {
                            if (!option.isHidden) {
                              return (
                                <li
                                  className={`item ${
                                    option.isActive ? "active" : ""
                                  } ${
                                    this.props.input.availability
                                      ? option.forbidden
                                        ? "forbidden"
                                        : "available"
                                      : ""
                                  }`}
                                  onClick={(event) => {
                                    if (!option.forbidden)
                                      this.onClick(
                                        input.inputId,
                                        input,
                                        event,
                                        option
                                      );
                                  }}
                                  key={index}
                                  value={option.id}
                                >
                                  <div className={"text"}>
                                    <span>{option.label}</span>
                                    {this.props.input.availability ? (
                                      <span
                                        className={`availability ${
                                          option.forbidden
                                            ? "forbidden"
                                            : "available"
                                        }`}
                                      >
                                        {option.forbidden
                                          ? "Booked"
                                          : "Available"}
                                      </span>
                                    ) : null}
                                  </div>
                                </li>
                              );
                            }
                          })
                        : ""}
                    </ScrollArea>
                  </div>
                </div>
              </div>

              <span className={"message"}>
                {context.getTranslate(input.errorMsg)}
              </span>
            </div>
          );
        }}
      </LanguageContext.Consumer>
    );
  }
}

Select.propTypes = {
  input: PropTypes.object.isRequired,
  changeInput: PropTypes.func.isRequired,
  blurInput: PropTypes.func,
  focusInput: PropTypes.func,
};

export default Select;
