import React from "react";
import PropTypes from "prop-types";
import "./Button.scss";
import { LanguageContext } from "../../../contexts/LanguageContext";
import Svg from "../SvgSprite/Svg";

const Button = ({
  text,
  onClick,
  className,
  link,
  svgName,
  isFile,
  loading,
  prefix,
  suffix,
}) => {
  const filePath = process.env.PUBLIC_URL + "/assets/files/";

  if (link) {
    return (
      <LanguageContext.Consumer>
        {(context) => {
          return (
            <a
              href={
                isFile
                  ? filePath + context.getTranslate(link)
                  : context.getTranslate(link)
              }
              target={"_blank"}
              rel={"noreferrer noopener"}
              className={`btn ${className}`}
              onClick={onClick}
            >
              {prefix}
              {context.getTranslate(text)}
              {suffix}
            </a>
          );
        }}
      </LanguageContext.Consumer>
    );
  } else if (svgName) {
    return (
      <LanguageContext.Consumer>
        {(context) => {
          return (
            <button className={`btn-icon ${className} ${context.dir}`}>
              <div className={"icon"}>
                <Svg name={svgName} />
              </div>
              <span>{context.getTranslate(text)}</span>
            </button>
          );
        }}
      </LanguageContext.Consumer>
    );
  } else {
    return (
      <LanguageContext.Consumer>
        {(context) => {
          return (
            <button
              className={`btn ${className}`}
              disabled={loading}
              onClick={onClick}
            >
              <span
                className={`loader ${loading ? "loader_loading" : ""}`}
              ></span>
              <span className={`${loading ? "text_hidden" : ""}`}>
                {context.getTranslate(text)}
              </span>
            </button>
          );
        }}
      </LanguageContext.Consumer>
    );
  }
};

Button.defaultProps = {
  className: "",
  onClick: () => {},
  text: "",
  svgName: null,
  prefix: null,
  suffix: null,
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  svgName: PropTypes.string,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
};

export default Button;
