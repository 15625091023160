import React from "react";
import features from "../../content/features.json";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import "./featuresSection.scss";
import Feature from "./Feature/Feature";

const FeaturesSection = () => {
  const [isMobile, setMobile] = React.useState(window.innerWidth < 768);

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setMobile(window.innerWidth < 768);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setMobile(window.innerWidth < 768);
      });
    };
  });
  SwiperCore.use([Pagination]);
  return (
    <section className={"pp-section pp-section-features no-top"}>
      <div className={"container"}>
        {!isMobile ? (
          features.map((feature, index) => {
            const { icon, text, color } = feature;
            return (
              <Feature key={index} color={color} text={text} icon={icon} />
            );
          })
        ) : (
          <Swiper pagination spaceBetween={8} slidePerView={1}>
            {features.map((feature, index) => {
              const { icon, text, color } = feature;
              return (
                <SwiperSlide key={index}>
                  <Feature color={color} text={text} icon={icon} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
    </section>
  );
};

export default FeaturesSection;
