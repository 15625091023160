import React from "react";
import { LanguageContext } from "../../../contexts/LanguageContext";
import "./radioGroup.scss";

class RadioGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: this.props.input,
      // input: {
      //     id: "radio",
      //     name: "radio",
      //     options: [
      //         {
      //             label: "Yes",
      //             value: true,
      //             checked: true
      //         },
      //         {
      //             label: "No",
      //             value: false
      //         },
      //         {
      //             label: "I don't know",
      //             value: null
      //         }
      //     ]
      // }
    };
  }

  onChange(value) {
    this.props.changeInput(this.state.input.id, this.state.input, value);
  }

  render() {
    const { input } = this.state;
    const { changeInput, focusInput, blurInput, direction } = this.props;

    return (
      <LanguageContext.Consumer>
        {(context) => {
          return (
            <div
              className={`radio-group ${input.hasError ? "has-error" : ""} ${
                direction || ""
              }`}
              dir={direction}
            >
              {input.options.map((option, index) => {
                return (
                  <label
                    onClick={() => {
                      this.onChange(option.value);
                    }}
                    htmlFor={option.id || `${input.name}-${index}`}
                    className={"radio"}
                    key={`radio-${index}`}
                  >
                    <input
                      checked={input.value === option.value}
                      defaultChecked={input.value === option.value}
                      id={option.id || `${input.name}-${index}`}
                      type={"radio"}
                      name={input.name}
                      value={option.value}
                    />
                    <span
                      className={"checkmark"}
                      onClick={() => {
                        this.onChange(option.value);
                      }}
                    />
                    {context.getTranslate(option.label)}
                  </label>
                );
              })}
            </div>
          );
        }}
      </LanguageContext.Consumer>
    );
  }
}

export default RadioGroup;
