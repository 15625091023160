import React from "react";
import PropTypes from "prop-types";
import "./Image.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Image = ({ src, alt, className, type, useLazy, placeholder }) => {
  const url = process.env.PUBLIC_URL + "/assets/images/";

  if (Array.isArray(src)) {
    const srcDefault = src[0];
    src.slice(0, 1);
    return (
      <div className={`image ${className} ${type}`}>
        <picture>
          {src.map((img) => (
            <source
              key={img.src}
              srcSet={url + img.src}
              media={`(max-width: ${img.media})`}
            />
          ))}
          <img src={url + srcDefault.src} alt={alt} />
        </picture>
      </div>
    );
  } else {
    return (
      <div className={`image ${className} ${type}`}>
        {useLazy ? (
          <LazyLoadImage
            alt={alt}
            src={url + src}
            effect={"blur"}
            placeholder={<img src={url + placeholder} />}
            placeholderSrc={url + placeholder}
          />
        ) : (
          <img src={url + src} alt={alt} title={alt} />
        )}
      </div>
    );
  }
};

Image.defaultProps = {
  className: "",
  type: "",
  alt: "",
  useLazy: true,
};

Image.propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  useLazy: PropTypes.bool,
};

export default Image;
