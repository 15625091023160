import React from "react";
import PropTypes from "prop-types";
import { LanguageContext } from "../../../contexts/LanguageContext";
import Parser from "html-react-parser";
import "./TitleBlock.scss";

const TitleBlock = ({ title, subtitle, link, isMain, className, height }) => {
  return (
    <LanguageContext.Consumer>
      {(context) => {
        return (
          <div
            className={`title-block ${className}`}
            style={height ? { height: `${height}px` } : {}}
          >
            {link ? (
              <a
                href={context.getTranslate(link)}
                target={"_blank"}
                rel={"noreferrer noopener"}
                className={"subtitle"}
              >
                {Parser(context.getTranslate(subtitle))}
              </a>
            ) : (
              <p className={"subtitle subtitle--accent-color"}>
                {Parser(context.getTranslate(subtitle))}
              </p>
            )}
            {isMain ? (
              <h1 className={"title"}>{Parser(context.getTranslate(title))}</h1>
            ) : (
              <h2 className={"title"}>{Parser(context.getTranslate(title))}</h2>
            )}
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

TitleBlock.defaultProps = {
  subtitle: "",
  isMain: false,
  className: "",
  useYoutube: false,
};

TitleBlock.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  isMain: PropTypes.bool,
  className: PropTypes.string,
  useYoutube: PropTypes.bool,
  dir: PropTypes.string,
};

export default TitleBlock;
