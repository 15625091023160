export const content = {
  title: "Этапы создания цифровой картины с печатью на холсте",
  subtitle: "Процесс работы",
  description:
    "Достоинством печати цифровой картины на холсте перед обычной картиной маслом являются скорость изготовления и более низкая конечная стоимость. В обоих случаях отрисовка картины – это полностью ручной труд. Выбор за вами.",
  cards: [
    {
      step: "1 этап",
      description:
        "Отрисовка картины вручную в приложении Procreate на iPad на основе вашей фотографии",
    },
    {
      step: "2 этап",
      description:
        "Перенос цифровой версии картины на хлопковый холст с помощью плоттера",
    },
    {
      step: "3 этап",
      description:
        "Натяжка готового холста на деревянный подрамник и установка крепежа",
    },
    {
      step: "4 этап",
      description:
        "Покрытие текстурным гелем или лаком для защиты холста и сохранения цветов картины",
    },
    {
      step: "5 этап",
      description:
        "По желанию, оформление в багет и упаковка картины в защитную или подарочную плёнку",
    },
    {
      step: "6 этап",
      description:
        "Доставка готовой картины транспортной компанией или самовывоз в г. Москва",
    },
  ],
};
