import React from "react";
import SvgSprite from "react-svg-sprite";

const Sprite = () => {
  return (
    <SvgSprite
      style={{
        position: "absolute",
        top: "-99999px",
        left: "-99999px",
        zIndex: "19999999",
        display: "block",
      }}
      symbols={[
        {
          name: "arrow",
          svg: `
					<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 15L8 8L1 0.999999" fill="none" />
					</svg>`,
        },
        {
          name: "arrow-reverse",
          svg: `
					<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M8 15L0.999999 8L8 0.999999" fill="none"/>
					</svg>
					`,
        },
        {
          name: "arrow-color-reverse",
          svg: `
						<svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M11 1L1 11L11 21" fill="none" />
						</svg>
					`,
        },
        {
          name: "arrow-color",
          svg: `
						<svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 1L11 11L1 21" fill="none" />
						</svg>
					`,
        },
        {
          name: "play",
          svg: `
						<svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.41431 1.69437L14.0625 10.8643L1.41431 20.0342L1.41431 1.69437Z" stroke="white"/>
						</svg>
					`,
        },
        {
          name: "menu",
          svg: `
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="5" width="24" height="2" rx="1" fill="#242424"/>
                        <rect y="11" width="24" height="2" rx="1" fill="#242424"/>
                        <rect y="17" width="24" height="2" rx="1" fill="#242424"/>
                        </svg>
                        `,
        },
        {
          name: "close",
          svg: `
					<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.7071 10.2929L17 5L18.4142 6.41421L13.1213 11.7071L18.4142 17L17 18.4142L11.7071 13.1213L6.41421 18.4142L5 17L10.2929 11.7071L5 6.41421L6.41421 5L11.7071 10.2929Z" />
</svg>
					`,
        },
      ]}
    />
  );
};

export default Sprite;
