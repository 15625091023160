import React from "react";
import { LanguageContext } from "../../contexts/LanguageContext";

import Sprite from "../../components/UI/SvgSprite/Sprite";
import Footer from "../../layouts/Footer/Footer";
import Header from "../../layouts/Header/Header";
import Hero from "../../layouts/Hero/Hero";
import FeaturesSection from "../../layouts/FeaturesSection/FeaturesSection";
import AboutSection from "../../layouts/AboutSection/AboutSection";
import PortfolioSection from "../../layouts/PortfolioSection/PortfolioSection";
import PricesSection from "../../layouts/PricesSection/PricesSection";
import ProcessSection from "../../layouts/ProcessSection/ProcessSection";
import ProductsSection from "../../layouts/ProductsSection/ProductsSection";
import FormSection from "../../layouts/FormSection/FormSection";
import PopupPolicy from "../../components/Popups/PopupPolicy/PopupPolicy";
import ArrayPopup from "../../components/Popups/ArrayPopup/ArrayPopup";
import PopupForm from "../../components/Popups/PopupForm/PopupForm";
import PopupFeedback from "../../components/Popups/PopupFeedback/PopupForm";
import PopupNotification from "../../components/Popups/PopupNotification/PopupForm";
import PopupMenu from "../../components/Popups/PopupMenu/PopupMenu";
import PopupThanks from "../../components/Popups/PopupThanks/PopupThanks";
import { ProcessOfWork } from "../../layouts/ProcessOfWork/ProcessOfWork";

const Main = ({}) => {
  const policyRef = React.createRef();
  const arrayPopupRef = React.createRef();
  const orderPopupRef = React.createRef();
  const thanksPopupRef = React.createRef();
  const [popupArray, setPopupArray] = React.useState([]);
  const [popupArrayPosition, setPopupArrayPosition] = React.useState(0);
  const [popupOrderType, setPopupOrderType] = React.useState("");

  React.useEffect(() => {});

  const openArrayPopup = (content, position) => {
    setPopupArray(content);
    setPopupArrayPosition(position);
    arrayPopupRef.current.open();
  };

  return (
    <LanguageContext.Consumer>
      {(context) => {
        return (
          <main className={`pp-page`}>
            {/*Попапы*/}
            <PopupMenu btnOpen={"js-open-menu"} />
            <ArrayPopup
              array={popupArray}
              position={popupArrayPosition}
              ref={arrayPopupRef}
            />
            <PopupForm
              ref={orderPopupRef}
              type={popupOrderType}
              btnOpen={"js-open-order"}
            />
            <PopupFeedback btnOpen={"js-open-feedback"} />
            <PopupNotification
              apiType={"Уведомление о старте курса"}
              prefix={"course-"}
              type={"course"}
              btnOpen={"js-open-course"}
            />
            <PopupNotification
              apiType={"Уведомление о начале продаж"}
              prefix={"product-"}
              type={"product"}
              btnOpen={"js-open-product"}
            />
            <PopupThanks ref={thanksPopupRef} />
            <PopupPolicy ref={policyRef} btnOpen={"js-open-terms"} />
            {/*Попапы*/}

            <Sprite />

            {/*Секции*/}
            <Header />
            <Hero />
            <FeaturesSection />
            <AboutSection />
            <PortfolioSection
              callback={(content, position) => {
                openArrayPopup(content, position);
              }}
            />
            <PricesSection
              onClick={(type) => {
                setPopupOrderType(type);
                orderPopupRef.current.open();
              }}
            />
            <ProcessSection
              callback={(content, position) => {
                openArrayPopup(content, position);
              }}
            />
            <ProcessOfWork />
            {/*<ProductsSection />*/}
            <FormSection
              callback={() => {
                thanksPopupRef.current.open();
              }}
            />
            <Footer />
            {/*Секции*/}
          </main>
        );
      }}
    </LanguageContext.Consumer>
  );
};

Main.propTypes = {};

export default Main;
