import React from "react";
import Form from "../../../components/Form/Form";
import Button from "../../../components/UI/Button/Button";
import API from "../../../Helpers/API";

class FeedbackForm extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.fields = [
      {
        id: "fb-name",
        type: "text",
        inputId: "fb-name",
        name: "name",
        validator: "name",
        value: "",
        label: "form.name",
        placeholder: "",
        inputClass: "",
        errorMsg: "form.name_error",
        isRequired: true,
      },
      {
        id: "fb-email",
        type: "email",
        inputId: "fb-email",
        name: "email",
        validator: "email",
        value: "",
        label: "form.email",
        placeholder: "",
        inputClass: "",
        errorMsg: "form.email_error",
        isRequired: true,
      },
      {
        id: "fb-message",
        type: "textarea",
        inputId: "fb-message",
        name: "message",
        validator: "message",
        value: "",
        label: "form.message",
        placeholder: "",
        inputClass: "",
        errorMsg: "form.message_error",
        isRequired: true,
      },
    ];
    this.state = {
      fields: this.fields,
      loading: false,
      data: {},
    };
  }

  onInputChange = (input) => {
    let data = this.state.data;
    data[input.name.replace(this.props.idPrefix, "")] = input.value;
    this.setState({ data });
  };

  forceValidate() {
    return this.formRef.current.forceValidate();
  }

  forceClose() {
    this.setState({ fields: this.fields }, () => {
      this.formRef.current.resetForm();
    });
  }
  onSubmit() {
    if (this.forceValidate()) {
      this.setState({ loading: true });
      let data = this.state.data;
      data["type"] = "Заполните форму для связи";
      data["token"] =
        "CQqUpvwVocwrpUdVrdfODcUYOeqqSztvqKpFfMBtPXLeSPajTfYxiSwWXMmQlFlB";
      API("", data, this.onSuccess.bind(this));
    }
  }

  onSuccess() {
    const fields = this.state.fields;
    fields.map((field) => {
      field.value = "";
      field.activePlaceholder = "";
    });
    this.setState({ fields: fields, loading: false, data: {} }, () => {
      this.formRef.current.resetForm();
    });
    this.props.onSubmit();
  }

  render() {
    const { fields } = this.state;
    return (
      <div className={"form form-main"}>
        <Form
          ref={this.formRef}
          embedded={true}
          fields={fields}
          onChangeInput={this.onInputChange.bind(this)}
        />
        <div className={"controls"}>
          <Button
            className={"bright"}
            onClick={this.onSubmit.bind(this)}
            text={"Отправить заявку"}
            loading={this.state.loading}
          />
          <span>
            Нажимая на кнопку «Отправить заявку», вы соглашаетесь с 
            <span className={"link js-open-terms"}>
              правилами обработки персональных данных
            </span>
          </span>
        </div>
      </div>
    );
  }
}

export default FeedbackForm;
