import React from "react";
import PropTypes from "prop-types";
import Select from "./Inputs/Select";
import TextInput from "./Inputs/TextInput";
import TextArea from "./Inputs/TextArea";
import RadioGroup from "./Inputs/RadioGroup";
import FileInput from "./Inputs/FileInput";

const Input = ({
  input,
  changeInput,
  idPrefix,
  changeSelect,
  focusInput,
  blurInput,
}) => {
  const inputs = {
    select: (
      <Select
        input={input}
        idPrefix={idPrefix}
        changeInput={changeSelect}
        focusInput={focusInput}
        blurInput={blurInput}
      />
    ),
    textarea: (
      <TextArea
        idPrefix={idPrefix}
        input={input}
        changeInput={changeInput}
        focusInput={focusInput}
        blurInput={blurInput}
      />
    ),
    // radio: <RadioGroup input={input} changeInput={changeInput} focusInput={focusInput} blurInput={blurInput}/>,
    image: (
      <FileInput idPrefix={idPrefix} input={input} onChange={changeInput} />
    ),
  };

  return (
    <div className={`input ${input.inputClass}`}>
      {inputs.hasOwnProperty(input.type) ? (
        inputs[input.type]
      ) : (
        <TextInput
          input={input}
          idPrefix={idPrefix}
          changeInput={changeInput}
          focusInput={focusInput}
          blurInput={blurInput}
        />
      )}
    </div>
  );
};

Input.propTypes = {
  input: PropTypes.object.isRequired,
  changeInput: PropTypes.func.isRequired,
  blurInput: PropTypes.func,
  focusInput: PropTypes.func,
};

export default Input;
