import React from "react";
import Image from "../UI/Images/Image";
import Button from "../UI/Button/Button";
import "./brushesBlock.scss";

export const BrushesBlock = () => {
  return (
    <div className={"brushes-block"}>
      <div className={"text"}>
        <span className={"hint"}>Обучение</span>
        <h4>Уроки по портрету и живописи</h4>
        <p>
          Основы перспективы, светотени, теории цвета, композиции. Пластическая
          анатомия, ракурсы, эмоции. Индивидуальный подход.
        </p>
      </div>
      <div className={"cta"}>
        <Image src={"desktop/products/brush.png"} className={"background"} />
        <Button className={"bright js-open-product"} text={"Оставить заявку"} />
      </div>
    </div>
  );
};
