import React from "react";
import Popup from "../Popup";
import Svg from "../../UI/SvgSprite/Svg";
import Image from "../../UI/Images/Image";
import Button from "../../UI/Button/Button";
import "./popupMenu.scss";
import { LanguageContext } from "../../../contexts/LanguageContext";

class PopupMenu extends Popup {
  constructor(props) {
    super(props);
    this.state = {
      nodeList: {},
    };
  }

  componentDidMount() {
    super.componentDidMount();
    let arr = {};
    document.querySelectorAll(".js-link").forEach((section) => {
      arr[section.dataset.label] = section;
    });
    this.setState({ nodeList: arr });
  }

  render() {
    const { nodeList } = this.state;

    return (
      <LanguageContext.Consumer>
        {(context) => {
          return (
            <div className={"pp-popup pp-popup-menu"} ref={this.ref}>
              <div className={"overlay"} />
              <div className={"close js-close"}>
                <Svg name={"close"} />
              </div>
              <div className={"box"}>
                <Image
                  className={"logo"}
                  useLazy={false}
                  src={`mobile/logo.svg`}
                />
                <nav className={"menu"}>
                  {Object.keys(nodeList).map((key, index) => {
                    return (
                      <span
                        onClick={() => {
                          this.close();
                          nodeList[key].scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                            inline: "nearest",
                          });
                        }}
                        key={index}
                      >
                        {key}
                        {key === "Мои продукты" ? (
                          <span className={"type"}>Скоро ⏳</span>
                        ) : null}
                      </span>
                    );
                  })}
                </nav>
                <div className={"controls"}>
                  <div className={"links"}>
                    <a
                      href={context.getTranslate("links.telegram")}
                      rel="noopener noreferrer"
                      target="_blank"
                      className={"telegram"}
                    >
                      <Image src={"desktop/icons/tg_icon.svg"} />
                      <div className={"text"}>
                        <span className={"title"}>
                          {context.getTranslate("links.instagram_text")}
                        </span>
                        <span className={"subtitle"}>написать в Telegram</span>
                      </div>
                    </a>
                    <a
                      href={context.getTranslate("links.email")}
                      className={"email"}
                    >
                      <Image src={"desktop/icons/email.svg"} />
                      <div className={"text"}>
                        <span className={"title"}>Email</span>
                        <span className={"subtitle"}>написать на почту</span>
                      </div>
                    </a>
                  </div>
                  <Button
                    className={"js-open-feedback dark"}
                    text={"Связаться"}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </LanguageContext.Consumer>
    );
  }
}

export default PopupMenu;
