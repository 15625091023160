import React from "react";
import "./Footer.scss";
import { LanguageContext } from "../../contexts/LanguageContext";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <LanguageContext.Consumer>
      {() => {
        return (
          <footer>
            <div className={"container"}>
              <span>Romanova-Art.ru © {year}</span>
            </div>
          </footer>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Footer;
