import React from "react";
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import Process from "../../content/process.json";
import "./processSection.scss";
import ProcessBlock from "./ProcessBlock/ProcessBlock";

const ProcessSection = ({ callback }) => {
  return (
    <section
      className={"pp-section pp-section-process"}
      data-label={"Условия заказа"}
    >
      <div className={"container"}>
        <TitleBlock
          title={"process.title"}
          subtitle={"process.subtitle"}
          className={"centered"}
        />
        <div className={"list"}>
          {Process.map((block, index) => {
            return (
              <ProcessBlock
                onClick={() => {
                  callback(Process, index);
                }}
                data={block}
                index={index}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ProcessSection;
