import React from "react";
import Popup from "../Popup";
import Svg from "../../UI/SvgSprite/Svg";
import TitleBlock from "../../UI/TitleBlock/TitleBlock";
import "./popupForm.scss";
import ThanksBlock from "./ThanksBlock/ThanksBlock";
import MainForm2 from "../../../layouts/FormSection/MainForm/MainForm2";

class PopupForm extends Popup {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
    };
  }

  onSubmit() {
    this.setState({ sent: true });
  }

  beforeClose() {
    this.setState({ sent: false });
  }

  render() {
    const { sent } = this.state;
    const { type } = this.props;

    return (
      <div className={"pp-popup pp-popup-order pp-popup-form"} ref={this.ref}>
        <div className={"close js-close"}>
          <Svg name={"close"} />
        </div>
        <div className={`box ${sent ? "sent" : ""}`}>
          <div className={"overlay js-close"} />
          <div className={"content"}>
            {!sent ? (
              <TitleBlock
                title={"Заполните форму для заказа"}
                subtitle={"Форма обратной связи"}
              />
            ) : (
              ""
            )}
            {!sent ? (
              <MainForm2
                onSubmit={this.onSubmit.bind(this)}
                idPrefix={"popup-"}
                type={type}
              />
            ) : (
              <ThanksBlock
                onClick={() => {
                  this.close();
                  this.setState({ sent: false });
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PopupForm;
