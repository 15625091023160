import React from "react";
import { LanguageContext } from "../../../../contexts/LanguageContext";
import Parser from "html-react-parser";
import Image from "../../../UI/Images/Image";
import ProcessBlock from "../../../../layouts/ProcessSection/ProcessBlock/ProcessBlock";

const PopupContentBlock = ({ element, onReady, key, position }) => {
  const [className, setClassName] = React.useState(
    element?.video && element?.popupType === "video" ? "paused" : ""
  );

  return (
    <LanguageContext.Consumer>
      {(context) => {
        return (
          <>
            {element?.popupTitle ? (
              <span className={`title`}>
                {Parser(context.getTranslate(element?.popupTitle))}
              </span>
            ) : null}
            <div className={`content  ${className}`}>
              {element?.image &&
              element?.popupType !== "video" &&
              element?.popupType !== "process" ? (
                <Image
                  useLazy={false}
                  src={element?.image}
                  className={"content-image"}
                />
              ) : null}
              {element?.popupType === "process" ? (
                <ProcessBlock extended={true} data={element} index={position} />
              ) : null}
            </div>
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default PopupContentBlock;
